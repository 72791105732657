ion-toggle {
  height: 1.9375rem;
  width: 3.1875rem;
  border-radius: 999px;

  --border-radius: 999px;

  --background: #dddddd;
  --background-checked: #04e9a6;

  --handle-background: white;
  --handle-background-checked: white;

  --handle-width: 1.6875rem;
  --handle-height: 1.6875rem;
  --handle-max-height: auto;
  --handle-spacing: 0.125rem;

  --handle-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  --handle-border-radius: 999px;
  --handle-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
    0px 3px 1px rgba(0, 0, 0, 0.06);

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}

ion-content::part(scroll) {
  overflow-y: auto !important;
}
